<template>
  <div class="home">
    <!-- HEADER AREA -->
    <div style="display: flex; justify-content: space-between;">
      <div v-if="detailPostObj === null && isAddingPost === false">
        <h2>Home</h2>
      </div>
      <div v-if="isAddingPost">
        <h2>New Post</h2>
      </div>
      <div class="post-title-holder" v-if="detailPostObj !== null">
        <div @click="clearPost" class="clickable back-arrow">
          <img class="inverse-img" src="../assets/back-arrow.png" width="20" height="20">
        </div>
        <h2>Post</h2>
      </div>
      <a @click="logout" class="clickable logout-text">logout</a>
    </div>
    <!-- NEW POST COMPONENT -->
    <newPost
       @close-window="isAddingPost = false"
       v-if="isAddingPost"
    />
    <!-- LIST OF POSTS W/LIKE COUNT -->
    <div v-if="detailPostObj === null && isAddingPost === false">
      <PostBasic
         v-for="(post, index) in postList"
         :key="index + post.title"
         :user="post.username"
         :post="post.content"
         :datePosted="post.datePosted"
         @post-selection="selectPost(post)"
      />
    </div>
    <!-- DETAILED POST VIEW -->
    <PostDetail
       v-if="detailPostObj != null"
       :user="detailPostObj.username"
       :post="detailPostObj.content"
       :datePosted="detailPostObj.datePosted"
    />

    <!-- Button to add a new post -->
    <div class="add-post-holder" @click="isAddingPost = true"
         v-if="!isAddingPost && !detailPostObj">
      <div class="add-post clickable">
        <img src="../assets/Vectoradd.svg" width="20" height="20">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PostBasic from '@/components/PostBasic.vue';
import PostDetail from '@/components/PostDetail.vue';
import NewPost from '@/components/NewPost.vue';

export default {
  name: 'Home',
  components: {
    PostBasic,
    PostDetail,
    NewPost,
  },
  data() {
    return {
      isAddingPost: false,
      detailPostObj: null,
    };
  },
  mounted() {
    // check to see if user has logged in, if not redirect them
    if (this.$store.state.user === '') {
      if (document.cookie.includes('testuser')) {
        this.$store.state.user = document.cookie.substring(document.cookie.indexOf('=') + 1);
      } else {
        document.location.assign('/signin');
      }
    }
    this.getPosts();
  },
  methods: {
    // take post object that's clicked and pass it to open the detailedPostComponent
    selectPost(post) {
      this.detailPostObj = post;
    },
    // close detailedPostComponent
    clearPost() {
      this.detailPostObj = null;
    },
    // pull posts from KV store in worker and create array out of them
    async getPosts() {
      let res = await fetch('https://be.13sfaith.workers.dev/posts');
      res = await res.json();
      res = JSON.parse(res);
      this.$store.state.posts = res;
    },
    // set cookie expiration to the past and then return user to signin page
    logout() {
      const date = new Date();
      date.setTime(date.getTime() - 1);
      document.cookie = `testuser=; expires=${date.toGMTString()}`;
      document.location.href = '/signin';
    },
  },
  computed: {
    // sort posts by most recent
    postList() {
      const ret = this.$store.state.posts;
      return ret.sort((a, b) => b.datePosted - a.datePosted);
    },
  },
};
</script>

<style scoped>
  .home {
    max-width: 650px;
    margin: auto;
    margin-top: 40px;
    position: relative;
    min-height: 98vh;
  }

  .inverse-img {
    filter: invert(100%);
  }

  .post-title-holder {
    display: flex;
    align-items: flex-start;
  }

  .post-title-holder > h2 {
    margin-left: 20px;
  }
  h2 {
    margin-top: 0;
  }
  .logout-text {
    color: var(--grey-color);
  }

  .add-post-holder {
    position: fixed;
    right: 30vw;
    bottom: 20px;
  }

  .add-post {
    display: grid;
    place-content: center;

    width: 40px;
    height: 40px;

    border-radius: 100%;
    background: var(--primary-color);
    box-shadow: 0px 1px 15px var(--primary-color);
  }

  .back-arrow {
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--black-color);
  }

  .back-arrow:hover {
    border: 1px solid var(--primary-color);
  }
</style>
