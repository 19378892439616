<template>
  <div class="post-basic-holder">
    <div @click="togglePost($event)" class="clickable post-holder">
      <div class="post-meta">
        <span>{{ user }}</span>
        <time-formatter :time="datePosted" />
      </div>
      <div class="post-details">{{ post }}</div>
      <div class="post-actions">
        <div style="flex: 1;"></div>
        <div @click="handleLike" class="clickable likeBtn">
          <img width="15" height="15" src="../assets/likeUnfilled.png" v-if="likedIndex === -1">
          <img width="15" height="15" src="../assets/likeFilled.png" v-else>
          <span>{{ likeCount }}</span>
        </div>
      </div>
    </div>
    <!-- LINE -->
    <div class="line-holder">
      <div class="line-obj"></div>
    </div>
  </div>
</template>

<script>
import TimeFormatter from '@/components/TimeFormatter.vue';

export default {
  name: 'PostBasic',
  components: {
    TimeFormatter,
  },
  props: ['user', 'post', 'datePosted'],
  methods: {
    // make sure the like button isn't pressed
    // if it isn't then open up post in detailed view
    togglePost(event) {
      if (event.srcElement.localName !== 'img'
        && !event.srcElement.classList.contains('likeBtn')) {
        if (!event.srcElement.parentElement
          || !event.srcElement.parentElement.classList.contains('likeBtn')) {
          this.$emit('post-selection');
        }
      }
    },
    // append user to likeArray for immediate feedback
    // then send update to the API to update the KV
    handleLike() {
      const index = this.$store.state.posts.findIndex(
        (post) => post.content === this.post && post.datePosted === this.datePosted,
      );
      if (this.likedIndex >= 0) {
        this.$store.state.posts[index].likeArray.splice(this.likedIndex, 1);
      } else if (this.$store.state.posts[index].likeArray === undefined) {
        this.$store.state.posts[index].likeArray = [this.$store.state.user];
      } else {
        this.$store.state.posts[index].likeArray.push(this.$store.state.user);
      }

      const req = new XMLHttpRequest();
      req.open('POST', 'https://be.13sfaith.workers.dev/posts/like', false);

      req.setRequestHeader('Content-Type', 'application/json');

      const newPostObj = {
        content: this.post,
        datePosted: this.datePosted,
        username: this.$store.state.user,
      };
      req.send(JSON.stringify(newPostObj));
    },
  },
  computed: {
    // helper functions to see:
    // if user has liked the post
    // where the post is in the main post array
    // number of likes on the post
    postIndex() {
      return this.$store.state.posts.findIndex(
        (post) => post.content === this.post && post.datePosted === this.datePosted,
      );
    },
    likeArray() {
      return this.$store.state.posts[this.postIndex].likeArray;
    },
    likeCount() {
      if (this.likeArray === undefined) {
        return 0;
      }
      return this.likeArray.length;
    },
    likedIndex() {
      if (this.likeArray === undefined) {
        return -1;
      }
      return this.likeArray.findIndex((post) => post === this.$store.state.user);
    },
  },
};
</script>

<style scoped>
  .post-basic-holder {
    max-width: 650px;
    margin: auto;
  }

  .post-holder {
    margin: 1px 20px;
  }

  h4 {
    margin: 2px 0;
  }

  .post-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey-color);
  }

  .post-details {
    margin: 7px 0;
  }

  .post-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .post-actions > div {
    display: flex;
    align-items: center;
  }

  .post-actions > div > span {
    color: var(--grey-color);
    font-size: 14px;
    margin-left: 5px;
  }

  .line-holder {
    margin: 10px 5px;
  }

  .line-obj {
    height: 1px;
    width: 100%;
    background: var(--dark-grey-color);
  }
</style>
