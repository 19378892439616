<template>
  <div>
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;
}

html {
  --black-color: #120204;
  --primary-color: #07E838;
  --secondary-color: #E80721;
  --grey-color: #908a8b;
  --dark-grey-color: #402324;
  --link-color: #1E3CE8;

  background-color: var(--black-color);
}

.clickable {
  cursor: pointer;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
