// this is a simple script that basically is just a data holder

<template>
  <div class="comment-holder">
    <div class="comment-header">
      <span>{{ user }}</span>
      <time-formatter :time="datePosted" />
    </div>
    <div>{{ comment }}</div>
  </div>
</template>

<script>
import TimeFormatter from '@/components/TimeFormatter.vue';

export default {
  name: 'CommentHolder',
  components: {
    TimeFormatter,
  },
  props: ['user', 'datePosted', 'comment'],
};
</script>

<style scoped>
.comment-holder {
  padding: 15px 20px;
  border-bottom: 1px solid var(--dark-grey-color);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  color: var(--grey-color);
}
</style>
