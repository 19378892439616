<template>
  <div class="new-post-holder">

    <div class="text-holder">
      <textarea
       v-model="post"
       placeholder="whats on your mind?"
       name="post"
       id="post"
       form="create-post"
      />
    </div>

    <div class="btn-holder">
      <input @click="cancelPost" class="clickable" type="button" value="cancel" />
      <input @click="createPost" class="clickable submit" type="submit" value="post" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPost',
  data() {
    return {
      post: '',
    };
  },
  methods: {
    // text post from textarea and post it to the KV store
    async createPost() {
      const date = new Date().getTime();

      const req = new XMLHttpRequest();
      req.open('POST', 'https://be.13sfaith.workers.dev/posts', false);

      req.setRequestHeader('Content-Type', 'application/json');

      const newPostObj = {
        content: this.post,
        username: this.$store.state.user,
        datePosted: date,
      };

      req.send(JSON.stringify(newPostObj));

      this.$store.state.posts.push(newPostObj);

      this.$emit('close-window');
    },
    // close new post component
    cancelPost() {
      this.$emit('close-window');
    },
  },
};
</script>

<style scoped>
  .new-post-holder {
    position: fixed;
    width: 650px;
    height: 310px;
    background: var(--black-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    top: 100px;

    box-shadow: 0px 0px 15px 0px var(--primary-color) inset;
    -webkit-box-shadow: 0px 0px 15px 0 var(--primary-color) inset;
    -moz-box-shadow: 0px 0px 15px 0 var(--primary-color) inset;
  }

  .text-holder {
    position: relative;
  }

  .text-holder:first-child {
    margin-top: 10px;
  }

  label {
    position: absolute;
    top: 0px;
    left: 35px;
    padding: 0 5px;
  }

  /*
  label > div {
    position: relative;
    top: 9px;
    left: -4px;
    width: 39px;
    height: 4px;
    background: var(--black-color);
  }
  */

  label > span {
    position: relative;
    top: -5px;
  }

  input[type="text"] {
    height: 40px;
    padding: 0px 20px;
  }

  textarea {
    height: 255px;
    padding: 10px 13px;
    width: calc(100% - 100px);
    resize: none;
  }

  input[type="text"]:focus,
  textarea:focus {
    outline: none;
  }

  input[type="text"],
  textarea {
    border: 1px solid #fff;
    border: none;
    border-radius: 9px;
    background: var(--black-color);
    margin: 0px 20px;
    color: #fff;
  }

  input[type="button"], input[type="submit"] {
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 9px;
    color: #fff;
  }

  input[type="button"] {
    background: var(--secondary-color);
  }
  input[type="submit"] {
    background: var(--primary-color);
  }

  input.submit {
    color: var(--black-color);
  }

  .btn-holder {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
</style>
