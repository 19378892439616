// simple script to add human readable post dates

<template>
  <span>{{ timeFormat }} </span>
</template>

<script>
export default {
  name: 'TimeFormatter',
  props: ['time'],
  computed: {
    // get the provided date and turn it into a human readable format (ex: 1d, 3h)
    timeFormat() {
      const today = new Date();
      const given = new Date(this.time);

      // break down the time into sections
      const seconds = Math.floor((today - given) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      let ret = '';

      if (days > 1) {
        ret = `${days}d`;
      } else if (hours > 0) {
        ret = `${hours}h`;
      } else if (minutes > 0) {
        ret = `${minutes}m`;
      } else {
        ret = `${seconds}s`;
      }

      return ret;
    },
  },
};
</script>
