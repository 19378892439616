<template>
  <div class="post-basic-holder">
    <div class="post-holder">
      <div class="post-meta">
        <span>{{ user }}</span>
        <time-formatter :time="datePosted" />
      </div>
      <div class="post-details">{{ post }}</div>
      <div class="post-actions">
        <div style="flex: 1;">
        </div>
        <div @click="handleLike" class="clickable">
          <img width="15" height="15" src="../assets/likeUnfilled.png" v-if="likedIndex === -1">
          <img width="15" height="15" src="../assets/likeFilled.png" v-else>
          <span>{{ likeCount }}</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px;">
      <div class="new-comment-holder" style="position: relative;">
        <textarea v-model="tempComment" placeholder="new comment" />
        <button @click="postComment" class="clickable">Post</button>
      </div>
    </div>
    <div>
      <h3 style="margin-left: 9px;">Comments</h3>
      <comment-holder
        :user="comment.postUser"
        :datePosted="comment.dateCommented"
        :comment="comment.comment"
        :key="comment.dateCommented"
        v-for="comment in commentList" />
    </div>
  </div>
</template>

<script>
import TimeFormatter from '@/components/TimeFormatter.vue';
import CommentHolder from '@/components/CommentHolder.vue';

export default {
  name: 'PostDetail',
  components: {
    TimeFormatter,
    CommentHolder,
  },
  props: ['user', 'post', 'datePosted'],
  data() {
    return {
      commentList: [],
      tempComment: '',
    };
  },
  mounted() {
    // get comments from post
    this.getComments();
  },
  methods: {
    async getComments() {
      const comments = await fetch(`https://be.13sfaith.workers.dev/post/detail/${this.user.split(' ').join('')}/${this.datePosted}`);
      this.commentList = await JSON.parse(await comments.json());
      this.commentList = this.commentList || [];
      this.commentList.sort((a, b) => b.dateCommented - a.dateCommented);
    },
    // same like code as the basic post
    // should be moved up to either the Home view or the state as a mutation
    // to prevent duplicate code
    handleLike() {
      const index = this.$store.state.posts.findIndex(
        (post) => post.content === this.post && post.datePosted === this.datePosted,
      );
      if (this.likedIndex >= 0) {
        this.$store.state.posts[index].likeArray.splice(this.likedIndex, 1);
      } else if (this.$store.state.posts[index].likeArray === undefined) {
        this.$store.state.posts[index].likeArray = [this.$store.state.user];
      } else {
        this.$store.state.posts[index].likeArray.push(this.$store.state.user);
      }

      const req = new XMLHttpRequest();
      req.open('POST', 'https://be.13sfaith.workers.dev/posts/like', false);

      req.setRequestHeader('Content-Type', 'application/json');

      const newPostObj = {
        content: this.post,
        datePosted: this.datePosted,
        username: this.$store.state.user,
      };
      req.send(JSON.stringify(newPostObj));
    },
    // generate comment object, add it to the comment array and update the API
    postComment() {
      const payload = {
        // use this to prevent weird spacing issues
        orgUser: this.user.split(' ').join(''),
        datePosted: this.datePosted,
        postUser: this.$store.state.user,
        dateCommented: new Date().getTime(),
        comment: this.tempComment,
      };

      const req = new XMLHttpRequest();
      req.open('POST', 'https://be.13sfaith.workers.dev/post/comment', false);
      req.setRequestHeader('Content-Type', 'application/json');
      req.send(JSON.stringify(payload));

      this.commentList.unshift({
        comment: this.tempComment,
        dateCommented: new Date().getTime(),
        postUser: this.$store.state.user,
      });

      this.tempComment = '';
    },
  },
  computed: {
    // like array helper functions (could/should be optimized)
    postIndex() {
      return this.$store.state.posts.findIndex(
        (post) => post.content === this.post && post.datePosted === this.datePosted,
      );
    },
    likeArray() {
      return this.$store.state.posts[this.postIndex].likeArray;
    },
    likeCount() {
      if (this.likeArray === undefined) {
        return 0;
      }
      return this.likeArray.length;
    },
    likedIndex() {
      if (this.likeArray === undefined) {
        return -1;
      }
      return this.likeArray.findIndex((post) => post === this.$store.state.user);
    },
  },
};
</script>

<style scoped>
  .post-basic-holder {
    max-width: 650px;
    min-height: calc(100vh - 150px);
    margin: auto;
    border-left: 1px solid var(--dark-grey-color);
    border-right: 1px solid var(--dark-grey-color);
  }

  .post-holder {
    margin: 1px 20px;
  }

  h4 {
    margin: 2px 0;
  }

  .new-comment-holder {
    position: relative;
  }

  .new-comment-holder > button {
    position: absolute;
    right: 28px;
    bottom: 5px;
  }

  .post-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey-color);
  }

  .post-details {
    margin: 7px 0;
  }

  .post-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .post-actions > div {
    display: flex;
    align-items: center;
  }

  .post-actions > div > span {
    color: var(--grey-color);
    font-size: 14px;
    margin-left: 5px;
  }

  .line-holder {
    margin: 10px 5px;
  }

  .line-obj {
    height: 1px;
    width: 100%;
    background: var(--dark-grey-color);
  }

  textarea {
    height: 130px;
    padding: 10px 13px;
    width: calc(100% - 68px);
    resize: none;
  }

  input[type="text"]:focus,
  textarea:focus {
    outline: none;
  }

  input[type="text"],
  textarea {
    border: 1px solid var(--dark-grey-color);
    border-radius: 9px;
    background: var(--black-color);
    margin: 0px 20px;
    color: #fff;
  }

  button {
    width: 50px;
    height: 30px;
    border: none;
    border-radius: 9px;
    color: var(--black-color);
    background: var(--primary-color);
  }
</style>
